import React, { Fragment } from 'react'
import { StartContainer, Illustrations } from './../styles/startStyles'
import { CircleIllu } from './../styles/globalStyles'
import Layout from '../components/layout'
import LineChart from '../components/LineChart/LineChart'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'

const LandingPage = () => (
  <Fragment>
    <Helmet>
      <meta charSet='utf-8' />
      <meta name="slack-app-id" content="A011P0HTX37"></meta>
      <title>Monitor website for uptime & performance | Sitepillow </title>
      <meta
        name='description'
        content='Monitor your website for uptime and performance. Get notified by phone, Slack, or e-mail.'
      />
      <link rel='canonical' href='https://sitepillow.com' />
    </Helmet>
    <StartContainer>
      <div className='sell-copy'>
        <h1>Monitor your websites uptime and performance</h1>
        <Link to={'/home'}>Get started</Link>
        <Link to={'/home'}>Compare features</Link>
      </div>
    </StartContainer>
    <Illustrations>
      <LineChart />
      <CircleIllu />
    </Illustrations>
  </Fragment>
)

export default () => (
  <Layout>
    <LandingPage />
  </Layout>
)
